import strapi from '@cannect/services/strapi'
import { AxiosResponse } from 'axios'
import BreadCrumb from 'components/BreadCrumb'
import BeginCheckout from 'components/Home/BeginCheckout'
import ModalHome from 'components/Home/ModalHome'
import Heading from 'new-components/Heading'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { useEffect, useState } from 'react'
import * as Styles from './styles'

function NutraceuticosAnsInso() {
  const [data, setData] = useState(null)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result: AxiosResponse = await strapi.get(`/api/nutraceuticos/2?populate=*`)
        setData(result.data.data)
      } catch (error) {
        console.error('Error fetching data: ', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (!data?.attributes) {
    return <div>Some required data is missing.</div>
  }

  const { Title = 'Default Title', Subtitle1, parceria, link1, Imagem } = data.attributes || {}

  // Extract and sort paragraph keys
  const paragraphs = Object.keys(data.attributes)
    .filter((key) => key.startsWith('Paragrafo'))
    .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))

  return (
    <Styles.Wrapper>
      <BreadCrumb
        paths={[
          {
            label: 'Suplementos',
            link: '/nutraceuticos'
          },
          { label: 'Protocolo de Ansidade e Insônia', link: `/nutraceuticos/protocolo-ansiedade-e-insonia` }
        ]}
      />
      {data && (
        <Styles.SectionMedicalTreatments>
          <Heading elementType="h2">
            <span>{Title}</span>
          </Heading>

          {Subtitle1 && (
            <Heading elementType="h3" fontSize="1.5625rem">
              {Subtitle1}
            </Heading>
          )}

          {parceria && <TextDanger text={parceria} />}

          {link1 && <TextDanger text={link1} />}

          {paragraphs.map((key) => {
            const paragraph = data.attributes[key]
            return (
              paragraph?.Titulo && (
                <div key={key}>
                  <Heading elementType="h2" fontSize="1.5625rem">
                    {paragraph.Titulo}
                  </Heading>
                  <TextDanger text={paragraph.Conteudo || paragraph.Sobre} />
                  {key === 'Paragrafo6' && Imagem?.data?.[0]?.attributes?.url && (
                    <Styles.StartTreatmentImageWrapper>
                      <Styles.StartTreatmentImage01
                        image={`${import.meta.env.VITE_APP_STRAPI_URL}${Imagem.data[0].attributes.url}`}
                        role="img"
                        aria-label="cannect desk image"
                      />
                    </Styles.StartTreatmentImageWrapper>
                  )}
                </div>
              )
            )
          })}
        </Styles.SectionMedicalTreatments>
      )}

      <ModalHome isOpen={modal} setOpenModal={setModal}>
        <BeginCheckout />
      </ModalHome>
    </Styles.Wrapper>
  )
}

export default NutraceuticosAnsInso
