import { Button, FormController, Typography } from '@cannect/new-components/atoms'
import { InputField } from '@cannect/new-components/molecules'
import { useConfirmIdentificationStep } from './useConfirmIdentificationStep'

export const ConfirmIdentificationStepUI = ({
  confirmIdentificationForm,
  onSubmit,
  control,
  isSubmitting,
  handleSubmit
}: ReturnType<typeof useConfirmIdentificationStep>) => {
  return (
    <div className="flex flex-col">
      <div className="mb-4 flex flex-col items-center gap-2">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          Preencha os dados do titular
        </Typography.Heading>
        <Typography.Text type="paragraphTwo" className="text-center">
          Use as informações do documento anexado.
        </Typography.Text>
      </div>

      <FormController.Form {...confirmIdentificationForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 rounded bg-white p-4 shadow-md">
            <Typography.Text type="paragraphTwo" className="text-primary-900" weight="medium">
              Dados pessoais
            </Typography.Text>
            <InputField variant="filledLight" name="full_name" label="Nome completo" control={control} />

            <InputField
              variant="filledLight"
              name="birthday"
              label="Data de nascimento"
              control={control}
              mask="DayMonthYear"
            />
            <InputField variant="filledLight" name="cpf" label="CPF" control={control} mask="cpf" />
            <InputField variant="filledLight" name="rg" label="RG" control={control} mask="rg" />
          </div>
          <Button className="mt-8 font-medium" full type="submit" isLoading={isSubmitting} size="lg">
            Confirmar
          </Button>
        </form>
      </FormController.Form>
    </div>
  )
}
